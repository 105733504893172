import React from "react";
import SingleServicePage from "../../components/SingleServicePage";
import withLayout from "../../layout";
import {injectIntl} from "react-intl";
import SEO from "../../components/SEO";
import mantegna from '../../images/portfolio/Mantegna.mp4';
import MantegnaThumb from '../../images/portfolio/MANTEGNA_thumbnail.webp';
import translated from "../../images/portfolio/Translated.mp4";
import translatedThumb from "../../images/portfolio/TRANSLATED_thumbnail.webp";
import maui from "../../images/portfolio/Mauijim.mp4";
import mauiThumb from "../../images/portfolio/MAUI-JIM_thumbnail.webp";
import bancaAnagni from "../../images/portfolio/BA.mp4";
import BancaAnagniThumb from "../../images/portfolio/BANCA AGAGNI_thumbnail.webp";
import insieme from "../../images/portfolio/Insieme.mp4";
import insiemeThumb from "../../images/portfolio/INSIEME_thumbnail.webp";
import enel from "../../images/portfolio/Enel.mp4";
import EnelThumb from "../../images/portfolio/ENEL_thumbnail.webp";
import acea from "../../images/portfolio/Acea.mp4";
import AceaThumb from "../../images/portfolio/ACEA_thumbnail.webp";
import ploom from "../../images/portfolio/Ploom.mp4";
import PloomThumb from "../../images/portfolio/PLOOM_thumbnail.webp";
import fiorentina from "../../images/portfolio/Fiorentina.mp4";
import FiorentinaThumb from "../../images/portfolio/FIORENTINA_thumb.webp";
import drop from '../../images/service_detail_drops/black.svg';
import {BulletBlackIcon} from "../../images/icons";

/**
 * note that the key we defined in role is needed for building the SingleServicePage
 * component with the right translation strings and media
 * @returns { SingleServicePage }
 * @constructor
 */

const SingleServicePageWrapper = ({intl}) => {
    const IntlTextObj = {
        title: 'designdev.title',
        colorName: 'black',
        color: '#000',
        drop: drop,
        bullet: <BulletBlackIcon/>,
        fromService: 'designdev',
        paragraphs: [
            {title: 'designdev.digital_product_title', text: 'designdev.digital_product_desc'},
            {title: 'designdev.website_title', text: 'designdev.website_desc'}
        ],
        services_detail: ['designdev.detail_1','designdev.detail_2','designdev.detail_3','designdev.detail_4','designdev.detail_5',
            'designdev.detail_6','designdev.detail_7','designdev.detail_8','designdev.detail_9','designdev.detail_10',
            'designdev.detail_11','designdev.detail_12'],
        clients: [
            {
                video: translated,
                title: 'portfolio.translated.title',
                poster: translatedThumb,
                subtitle: 'portfolio.translated.description',
                to: '/case-study/translated',
            },
            {
                video: maui,
                title: 'portfolio.maui.title',
                poster: mauiThumb,
                subtitle: 'portfolio.maui.description',
                to: '/case-study/maui',
            },
            {
                video: bancaAnagni,
                title: 'portfolio.bancaAnagni.title',
                poster: BancaAnagniThumb,
                subtitle: 'portfolio.bancaAnagni.description',
                to: '/case-study/bancaAnagni',
            },
            {
                video: insieme,
                title: 'portfolio.insieme.title',
                poster: insiemeThumb,
                subtitle: 'portfolio.insieme.description',
                to: '/case-study/insieme',
            },
            {
                video: mantegna,
                title: 'portfolio.mantegna.title',
                poster: MantegnaThumb,
                subtitle: 'portfolio.mantegna.description',
                to: '/case-study/mantegna',
            },
            {
                video: enel,
                title: 'portfolio.enel.title',
                poster: EnelThumb,
                subtitle: 'portfolio.enel.description',
                to: '/case-study/enel',
            },
            {
                video: acea,
                title: 'portfolio.acea.title',
                subtitle: 'portfolio.acea.description',
                poster: AceaThumb,
                to: '/case-study/acea',
            },
            {
                video: ploom,
                title: 'portfolio.ploom.title',
                poster: PloomThumb,
                subtitle: 'portfolio.ploom.description',
                to: '/case-study/jti',
            },
            {
                video: fiorentina,
                title: 'portfolio.fiorentina.title',
                poster: FiorentinaThumb,
                subtitle: 'portfolio.fiorentina.description',
                to: '/case-study/fiorentina',
            }
        ]
    };

    const keywords = intl.formatMessage({id: 'designdev.meta_keywords'}).split(',') || [];

    return <>
        <SEO title={intl.formatMessage({id: 'designdev.meta_title'})}
             description={intl.formatMessage({id: 'designdev.meta_description'})} keywords={keywords}
             lang={intl.locale}/>
        <SingleServicePage IntlTextObj={IntlTextObj} intl={intl}/>
    </>
};

const customProps = {
    localeKey: "designdev", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(SingleServicePageWrapper));
